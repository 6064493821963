<template>
  <div class="w100 h-60 dp-f jc-sb ai-c bg-fff" style="box-shadow: 0px 9px 7px 0px rgba(169,169,169,0.16);">
    <div class="ml-33 dp-f ai-c ">
      <el-image class="w-16 h-16 mr-3 cu-p" :src="require('@/assets/img/home/left.png')" />
      <span @click="router_push_name('operation_myTemplate', undefined, undefined, true)" class="cu-p">返回上一页</span>
      <div class="ml-43 ">

        <div class="dp-f fs-20 ai-c">
          <div v-show="state.flag == false">
            {{ state.templateTitle }}
          </div>
          <el-input class="input search_input" placeholder="请输入模版名称" v-show="state.flag == true"
            v-model="state.templateTitle" :maxlength="config.inputMaxlength" show-word-limit />
          <!-- <el-input v-model="state.templateTitle" v-else></el-input> -->
          <el-image class="w-16 h-16 ml-9 mr-3 cu-p" @click="revise" :src="require('@/assets/img/template/edit.png')" />
          <span class="tag ml-21 fs-14">
            <!-- <li>已启用</li> -->
          </span>
        </div>
        <!-- <div class="mt-4 c-666">保存成功，更新时间2022-11-09</div> -->
      </div>
    </div>
    <div class="dp-f ai-c mr-20">
      <!-- <div class="dp-f ai-c mr-20 cu-p">
        <el-image class="w-18 h-18 mr-7" :src="require('@/assets/img/template/out.png')" />
        停用
      </div>
      <el-divider direction="vertical" />
      <div class="dp-f ai-c ml-18 mr-18 cu-p">
        <el-image class="w-18 h-18 mr-7" :src="require('@/assets/img/template/sys.png')" />
        设置
      </div>
      <el-divider direction="vertical" />
      <div class="dp-f ai-c ml-18 cu-p">
        <el-image class="w-18 h-18 mr-7" :src="require('@/assets/img/template/download.png')" />
        下载
      </div> -->
      <!-- <oabutton title=预览 CSStype=3 width=96 height=42 class="ml-42 mr-10"></oabutton> -->
      <oabutton v-model:loading="state.loading" title=新增 CSStype=2 width=96 height=42 @click="emit('add')" v-if="props.addorsave"></oabutton>
      <oabutton v-model:loading="state.loading" title=保存 CSStype=2 width=96 height=42 @click="emit('save')" v-else></oabutton>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, defineEmits, reactive, defineExpose } from "vue";
import workbenchbox from "@/components/layout/workbenchbox.vue";
import { router_back, router_push_name } from "@/utils/server/router.js";
const emit = defineEmits(['save', 'add', 'change'])
const state = reactive({
  flag: false,
  templateTitle: '',
  loading: false,
})
const setLoading=(bol)=>{
  state.loading=bol
}
const props = defineProps({
  addorsave: {
    type: Boolean,
    default: false
  },
})
const revise = () => {
  state.flag = !state.flag
  emit('change', state.templateTitle)
}
defineExpose({
  state,
  setLoading
});
</script>

<style lang="scss" scoped></style>