<template>
  <div class="w-360 fs-0 bg-fff mb-5 topbox">
    <div class="pl-20 h-60 fw-b w100 pr-20 dp-f jc-sb ai-c" style="border-color: #e2e2e2">
      <div>参数列表</div>
      <img src="@/assets/img/template/add.png" alt="" class="cu-p" @click="showDialog" />
    </div>
    <div class="pr-20 pl-20 w100">
      <el-table :data="state.Notice" class="mb-20" max-height="650px" :header-cell-style="{
        background: '#EAEAEA',
        color: '#666666',
        // fontWeight: config.table.fontWeight,
        fontSize: '12px',
      }" row-key="id">
        <el-table-column :align="'center'" prop="name" label="参数名称" />
        <el-table-column :align="'center'" prop="nameKey" label="参数标志" />
        <el-table-column :align="'center'" label="操作" width="90">
          <template #default="scope">
            <div class="dp-f jc-sb">
              <div class="Editor_span" style="fontsize: 12px"  @click="detele(scope.row)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <adddialog ref="addref" @Emit="AddParam"></adddialog>
  </div>
</template>
<script setup>
import adddialog from "./dialog.vue";
import { ref, reactive, onMounted, unref, defineEmits, watch } from "vue";
import { debounce, arrDeteleItem } from "@/utils/util.js";
import { useStore } from "vuex";
import qs from "qs";
import { httpToken } from "@/utils/request";
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import { objdata_ret, objdata_set } from "@/utils/server/format";
const store = useStore();
// const emit = defineEmits(["Emit"]);
const addref = ref();
const state = reactive({
  Notice: [],
  paramslist: [],
});
let props = defineProps({
  basefilesList: {
    type: Array
  },
  params: {
    type: Array
  }
})

watch(
  () => props.params,
  (value, oldValue) => {
    console.log("watch:", value)
    state.Notice = value
  }, {deep: true}
  // { immediate: true } //初始化时立即执行回调函数
);

const AddParam = (e) => {
  let param = {}
  param.name = e.parameterName
  param.nameKey = e.parameterKey
  state.Notice.push(param)
}

const showDialog = () => {
  if (props.basefilesList[0].pages.length == 0) {
    handleMessage('请先上传文件', 'info')
    return
  }
  unref(addref).show()
}

// const getAllList = () => {
//   httpToken({
//     method: "post",
//     url: "/admin/template-dynamic-filed/all",
//   }).then((res) => {
//     console.log(res);
//     res.data.forEach(item => {
//       item.parameterLength=item.parameterLength/20
//     });
//     state.Notice = res.data;
//   });
// };
// getAllList();

const detele = (el) => {
  setTimeout(() => {
    arrDeteleItem(state.Notice, el, "key");
  }, 100);
};

// const deteleparams = (row) => {
//   handleCofirm("你确定要删除这个参数吗")
//     .then((res) => {
//       httpToken({
//         method: "post",
//         url: "/admin/template-dynamic-filed/del",
//         data: qs.stringify({ ids: row.id }),
//       }).then((res) => {
//         emit("delEmit", row);
//         handleMessage("删除成功", "success");
//         getAllList();
//       });
//     })
//     .catch((e) => {
//       handleMessage("取消删除", "info");
//     });
// };
const getParams = () => {
  return state.Notice
}

onMounted(() => { });
defineExpose({
  // paramslist: state.paramslist,
  // copy,
  // detele,
  getParams,
});
</script>
<style lang="scss" scoped>
.topbox {
  // max-height: calc(100vh - 100px - 88px) !important;.
  height: calc(100vh - 100px - 128px);
  overflow: auto;
}
</style>