<template>
  <workbenchbox>
    <headNavigation @save="save" ref="headref" @add="add" @change="change" :addorsave="!Route.params.id"></headNavigation>
    <div class="mt-10 mb-5 dp-f topbox">
      <div class="signbox dp-f mr-5">
        <div class="w100 ptbox pt-r">
          <div v-loading="loading" :element-loading-background="config.loading.background" v-if="state.uploaddiv"
            class="w100 h-300 dp-f ai-c jc-c">
            <el-upload class="mr-12" :accept="tem_accept" :show-file-list="false" :http-request="httpRequest">
              <div class="butbox">
                <img src="@/assets/img/initiateContract/fileicon.png" />
                请点击上传WORD模板文件
              </div>
            </el-upload>
          </div>
          <div class="imgbox autobox dp-fc w100 h100 fw-w" ref="autoboxRef" v-else>
            <div v-for="(el, ind) in state.basefilesList[state.basefilesIndex]
              .pages" :key="ind" class="mb-30 w100 dp-f jc-c" @drop="dropEvent($event)">
              <div class="pt-r mw-80">
                <img :src="el.fileUrl" ref="fileimgRef" :style="{
                  width: state.fileImgW * (state.percentage / 100) + 'px',
                }" />
                <!-- 所有签署印章 -->
                <template v-for="(item, index) in state.sealsigns" :key="index">
                  <!-- v-if="item.basefilesIndex == state.basefilesIndex && item.pageIndex == ind + 1 && !item.ishide" -->
                  <VueDragResize v-if="item.pageIndex == ind + 1 && !item.ishide" class="sealbox" :id="item.id"
                    :parentW="state.parentW" :parentH="state.parentH" :x="item.left" :y="item.top"
                    :parentLimitation="true" :isActive="true" :isResizable="false" :aspectRatio="true"
                    :w="item.parameterLength" :h="item.parameterHeight" :isDraggable="item.isDraggable"
                    @dragstop="dragstop($event, item)">
                    <div class="w100 h100 dp-fc signitembox"  @click="state.nowSeal = item">
                      <div class="w-100 h-20">
                        {{ item.parameterName }}
                      </div>
                      <operations v-if="state.nowSeal == item" :item="item" @copyClick="unref(parameterref).copy($event)"
                        @deteleClick="deteleClick" />
                    </div>
                  </VueDragResize>
                </template>
              </div>
            </div>
          </div>

          <pagesNavigation class="pagesNavigation pt-a" ref="pagesNavigationRef" @Emit="changePercentage">
          </pagesNavigation>
        </div>
      </div>
      <parameter :params="state.params" ref="parameterref" :basefilesList="state.basefilesList" />
      <!-- <library :paramsNumber="state.paramsNumber"></library> -->
    </div>
  </workbenchbox>
</template>

<script setup>
import { ref, onMounted, reactive, unref, computed, watch, nextTick } from "vue";
import VueDragResize from "vue-drag-resize/src"; //拖动组件
import operations from "./components/createPage/operation.vue";
import workbenchbox from "@/components/layout/workbenchbox.vue";
import { tem_accept } from "@/utils/base/accept.js";
import { getfileTypeName } from "@/utils/server/file.js";
import headNavigation from "./components/createPage/headNavigation.vue"; //头导航
import parameter from "./components/createPage/parameter.vue"; //参数详情
import library from "./components/createPage/library.vue"; //参数库
import pagesNavigation from "./components/createPage/pagesNavigation.vue"; //分页计数组件
import { fileByPdf, getpdfByPic, uploadFile } from "@/utils/server/upload.js";
import { httpToken } from "@/utils/request";
import { mul, div } from "@/utils/server/format.js";
import { useStore } from "vuex";
import { handleMessage } from "@/utils/server/confirm";
import { router_back, router_push_name } from "@/utils/server/router";
import qs from "qs";
import { useRoute } from "vue-router";
const headref = ref()
const Route = useRoute(); //获取到值
const autoboxRef = ref();
const store = useStore();
const pagesNavigationRef = ref(); //分页计算ref
const loading = ref(false);
const parameterref = ref();
const fileimgRef = ref(); //文件图片ref
const state = reactive({
  basefilesList: [{ pages: [] }], //所有文件列表信息
  basefilesIndex: computed(() => {
    return 0;
  }), //当前展示文件下标
  percentage: 100, //当前百分比
  fileImgW: 1190, //文件默认宽度
  parentW: "", //拖动组件边界
  parentH: "", //拖动组件边界
  uploadType: false, //上传类型（0 其他，1 模板）
  uploaddiv: true,
  paramsNumber: [],
  imgidgroup: [], //所以图片id
  templateTitle: '',
  params: [],
  wordFileId: 0 
});

const change = (e) => {
  state.templateTitle = e
}

//上传文件
const httpRequest = (rawFile) => {
  let fileTypeName = getfileTypeName(rawFile.file.name);
  let filetype = [
    // "pdf",
    // "PDF",
    "doc",
    "DOC",
    "docx",
    "DOCX",
  ];
  if (rawFile.file.size / 1024 / 1024 > 500) {
    handleMessage("文档大小超过500MB");
  } else if (filetype.includes(fileTypeName)) {
    state.uploadType = 1;
    loading.value = true;
    // type:要转换的文件类型(0 word , 1 图片)
    uploadFile(rawFile.file, "null", "99999", true)
      .then((res) => {
        if (res.code == 200) {
          state.wordFileId = res.data.id
          getpdfByPic(rawFile.file.name, res.data.pdfUrl)
            .then((res2) => {
              state.basefilesList[0].pages = res2.data;
              state.imgidgroup = res2.data.map((v, ind) => {
                return { picId: String(v.id), page: ind + 1 }
              });
              unref(pagesNavigationRef).setCopies(res.data.length);
              // 重新设置页码大小
              unref(pagesNavigationRef).setTotalPages(
                state.basefilesList[state.basefilesIndex].pages.length
              );
              state.uploaddiv = false;
              loading.value = false;
            })
            .catch(() => {
              loading.value = false;
            });
        } else {
          loading.value = false;
        }
      })
      .catch(() => {
        loading.value = false;
      });
  } else {
    handleMessage("请上传正确文档格式格式!");
  }
};

//新增模版
const addtemplate = (url = "/admin/templateCon/add", id) => {
  if(state.templateTitle==''||state.templateTitle==null){
    handleMessage("请输入模板名称!");
    return
  }
  let params = unref(parameterref).getParams()
  if (params.length <= 0) {
    handleMessage("请输入模板参数!");
    return
  }
  // let contractFileds = getdata2(state.sealsigns);
  let data = {
    id: id,
    templateTitle: state.templateTitle,
    companyId: JSON.parse(window.sessionStorage.user).user_info.sysCompany
      .companyId,
    params: params,
    wordAndPics: state.imgidgroup,
    fileId: state.wordFileId
  };
  if (!id) {
    delete data.id;
  }
  unref(headref).setLoading(true)
  httpToken({
    method: "post",
    url: url,
    data: data,
  }).then((res) => {
    handleMessage("保存成功", "success");
    router_push_name("operation_myTemplate", undefined, undefined, true);
  }).catch(() => {
    unref(headref).setLoading(false)
  })
};
const add = () => {
  addtemplate();
};
const save = () => {
  addtemplate("admin/templateCon/update", Route.params.id);
};
const getdata2 = (signs) => {
  let data = [];
  signs.forEach((item) => {
    if (!item.ishide) {
      data.push({
        contractFileId: state.basefilesList[0].pages[item.pageIndex - 1].id, //合同文件id
        templateFieldId: item.id,
        x: item.signatureX ? item.signatureX : "0.000",
        y: item.signatureY ? item.signatureY : "0.000",
        page: item.pageIndex,
        leftDistance: String(item.left),
        top: String(item.top),
        timeId: String(item.timeId),
        // id:item.contractId?item.contractId:null     
      });
    }
  });
  return data;
};
//详情接口  数据回显
const getdatafind = (id) => {
  let json = [{ column: "id", type: "eq", value: id }];
  httpToken({
    method: "post",
    url: "/admin/templateCon/find",
    data: qs.stringify({
      paramData: JSON.stringify(json),
    }),
  }).then((res) => {
    state.templateTitle=res.data.templateTitle
    unref(headref).state.templateTitle=res.data.templateTitle
    state.uploaddiv = false;
    state.basefilesList[0].pages = res.data.sysFiles;
    state.imgidgroup = res.data.wordAndPics
    unref(pagesNavigationRef).setCopies(res.data.length);
    // 重新设置页码大小
    unref(pagesNavigationRef).setTotalPages(
      state.basefilesList[state.basefilesIndex].pages.length
    );
    // state.sealsigns=res.data.contractFileds
    let arr = [];
    res.data.params.forEach((v) => {
      state.params.push({
        id: v.id,
        templateId: v.templateId,
        name: v.name,
        nameKey: v.nameKey
        // contractId:v.id
      });
    });
    store.commit("setgetTemlist", arr);
  });
};
if (Route.params.id) {
  getdatafind(Route.params.id);
} else {
  store.commit("setgetTemlist", []);
}

watch(
  () => state.sealsigns,
  (value, oldValue) => {
    counting();
  },
  // { immediate: true } //初始化时立即执行回调函数
);

onMounted(() => {
  // // unref(headref).setLoading(true)
  // // unref(headref).setLoading(false)
  // nextTick(() => {
  //   window.addEventListener("scroll", getNowPageIndex, true);
  // });
  // state.percentage = computed(() => {
  //   return unref(pagesNavigationRef).state.percentage;
  // });
});
</script>

<style lang="scss" scoped>
.topbox {
  height: calc(100vh - 60px - 88px);
  overflow: hidden;
}

.signbox {
  width: calc(100vw - 360px);
  height: calc(100vh - 60px - 88px);
  // background-color: rebeccapurple;
  .signitembox {
    position: relative;
  }
}

.mw-80 {
  img {
    max-width: 100%;
  }
}

.pagesNavigation {
  bottom: 0;
  left: 0;
}

.ptbox {
  max-height: calc(100%);
  // max-height: calc(100% - 40px);
}

::v-deep .sealbox {
  border: 1px solid #ff0000 !important;
  background: rgba(255, 0, 0, 0.1);
}

.bfont {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
</style>